import { TextAreaInputShort } from "../../../components/atomic/commentsInput";
import { DiscussionComponent } from "../../../components/atomic/discussionComponent";
import { Answer } from "../../../types/Rows";

export const QuestionWithAnswer = ({
  index,
  input,
  comment,
  setComment,
  answer,
  setAnswer,
  depth = 0,
}: {
  index: string;
  input: Answer;
  comment: string;
  setComment: (name: string) => void;
  answer: string;
  setAnswer: (name: string) => void;
  depth: number;
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-11 max-h-50 break-words">
      <div
        className={`col-span-1 sm:col-span-7 flex items-center ${
          depth > 0 ? "pl-10" : "pl-2"
        } `}
      >
        <div className="pr-4">{index}</div> <div>{input.description}</div>
      </div>
      <div className="col-span-1 sm:col-span-2 flex flex-row gap-3">
        {!input.isBlank && (
          <>
            <div className="flex items-center justify-between p-1">
              <label>
                <input
                  defaultChecked={answer === "yes"}
                  type="radio"
                  onChange={(e: any) => {
                    setAnswer(e.target.value);
                  }}
                  value="yes"
                  name={"radio-" + input.questionId}
                />{" "}
                Tak
              </label>
            </div>
            <div className="flex items-center justify-between p-1">
              <label>
                <input
                  defaultChecked={answer === "no"}
                  type="radio"
                  value="no"
                  onChange={(e: any) => {
                    setAnswer(e.target.value);
                  }}
                  name={"radio-" + input.questionId}
                />{" "}
                Nie
              </label>
            </div>
            <div className="flex items-center justify-between p-1">
              <label>
                <input
                  defaultChecked={answer === "na"}
                  type="radio"
                  value="na"
                  onChange={(e: any) => {
                    setAnswer(e.target.value);
                  }}
                  name={"radio-" + input.questionId}
                />{" "}
                N/A
              </label>
            </div>
          </>
        )}
      </div>
      <div className="col-span-1 sm:col-span-2 items-center flex gap-5 p-1">
        <TextAreaInputShort
          maxLength={1000}
          rowSize={2}
          label="Komentarz"
          value={comment}
          onChange={(e) => {
            setComment(e);
          }}
        />

        <div className="self-center mr-1 cursor-pointer">
          <DiscussionComponent resourceId={input.answerId} />
        </div>
      </div>
    </div>
  );
};
