import React, { useState, useEffect } from "react";
import { fetchLogs } from "../../api/userApi"; // Import fetchLogs
import { Log } from "../../models/Log"; // Import Log model

const AuditComponent: React.FC = () => {
  const [items, setItems] = useState<Log[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    // Fetch logs from the API
    const fetchItems = async () => {
      const data = await fetchLogs(100, currentPage, itemsPerPage); // Pass parameters
      if (Array.isArray(data)) {
        setItems(data);
      }
    };

    fetchItems();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <h1>Audit Items</h1>
      <ul>
        {currentItems.map((item) => (
          <li key={item.id}>
            {item.message} - {item.created}
          </li>
        ))}
      </ul>
      <div>
        {Array.from(
          { length: Math.ceil(items.length / itemsPerPage) },
          (_, index) => (
            <button key={index} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default AuditComponent;
