import {
  Button,
  Checkbox,
  Option,
  Select,
  Textarea,
} from "@material-tailwind/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addQuestion, deleteQuestion } from "../../../api/reportsApi";
import { EditableReportQuestion } from "../../../types/Rows";
import AddItemModal from "../addItemModal";

export const SelectDefault = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (val) => void;
}) => {
  return (
    <div className="p-1">
      <Select value={value} onChange={setValue} label="Wymagana odpowiedz">
        <Option value="yes">Tak</Option>
        <Option value="no">Nie</Option>
        <Option value="na">N/A</Option>
      </Select>
    </div>
  );
};
export const Question = ({
  index,
  input,
  description,
  setDescription,
  templateId,
  isBlank,
  setIsBlank,
  childrenRequiredAnswer,
  setChildrenRequiredAnswer,
  sectionId,
  isLocked,
  depth,
}: {
  index: number;
  input: EditableReportQuestion;
  description: string;
  setDescription: (val: string) => void;
  templateId: string;
  isBlank: boolean;
  setIsBlank: (val: boolean) => void;
  childrenRequiredAnswer: string;
  setChildrenRequiredAnswer: (val: string) => void;
  sectionId: string;
  isLocked: boolean;
  depth: number;
}) => {
  const queryClient = useQueryClient();

  const { mutate: deleteItem } = useMutation(
    async ({ templateId, value }: { templateId: string; value: string }) =>
      deleteQuestion(templateId, value),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["Templates"]);
      },
    }
  );

  const { mutate: addItem } = useMutation(
    async ({
      templateId,
      sectionId,
      value,
    }: {
      templateId: string;
      sectionId: string;
      value: { description: string; parentId: string };
    }) => addQuestion(templateId, sectionId, value),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["Templates"]);
      },
    }
  );

  return (
    <>
      <div
        className={`flex gap-3 flex-row justify-between
                  max-h-50
                  m-1`}
      >
        {/* <div className={`p-${}`}></div> */}
        {/* <div className={`col-span-${1}`}>
          <input
            type="number"
            style={{ width: "100px" }}
            value={weight}
            onChange={(e) => setWeight(Number.parseInt(e.target.value) || 0)}
          />
        </div> */}
        <div style={{ width: "80%" }}>
          <div className={`ml-${3 * depth}`}></div>
          <div style={{ width: "100%" }}>
            <Textarea
              label="Opis pytania"
              className="border w-100"
              // rows={8}
              style={{ height: "100%", width: "100%" }}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-row">
          {depth > 0 && (
            <div className={`ml-${2 + depth - 1}`}>
              <SelectDefault
                value={childrenRequiredAnswer}
                setValue={setChildrenRequiredAnswer}
              />
            </div>
          )}

          <div className="pl-2">
            <label title="isBlank">
              <Checkbox
                defaultChecked={isBlank}
                onChange={(val) => setIsBlank(!isBlank)}
                name={"checkboxisblank-" + input.id}
              />{" "}
              Bez odpowiedzi Tak/Nie
            </label>
          </div>
          <div className="flex justify-around h-12">
            {depth < 2 && (
              <AddItemModal
                borderless
                text="Dodaj dziecko"
                saveItem={(val) => {
                  addItem({
                    templateId: templateId,
                    value: { description: val, parentId: input.id },
                    sectionId: sectionId,
                  });
                }}
              >
                +
              </AddItemModal>
            )}
            <Button
              variant="text"
              onClick={() => {
                const res = window.confirm("Czy chcesz usunąć?");
                if (res)
                  deleteItem({ templateId: templateId, value: input.id });
              }}
            >
              x
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
