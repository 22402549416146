import DatePicker from "react-date-picker";
// import DatePicker from "react-datepicker";

import { Tab, Tabs, TabsHeader } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useMetadata } from "../../..";
import { ItemPopover } from "../../../components/atomic/itemPopover";
import { formatToDateOnly } from "../../../components/lists/formatDate";
import { Metadata } from "../../../types/Metadata";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

enum VisitTypes {
  VISITNUMBER,
  VISITTYPE,
}
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export function UnderlineTabs({
  activeVisit,
  setActiveVisit,
}: {
  activeVisit: VisitTypes;
  setActiveVisit: (value) => void;
}) {
  const data = [
    {
      label: "Numer wizyty",
      value: VisitTypes.VISITNUMBER,
      desc: `Dotyczy raportu z IMV i remote IMV. Wpisz numer wizyty w postaci cyfry.`,
    },
    {
      label: "Typ wizyty",
      value: VisitTypes.VISITTYPE,
      desc: `Dotyczy raportu z SIV i COV. Wpisz odpowiedni typ: w ośrodku/zdalna/hybrydowa.`,
    },
  ];
  return (
    <Tabs value={activeVisit}>
      <TabsHeader
        className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
        }}
      >
        {data.map(({ label, value, desc }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveVisit(value)}
            className={activeVisit === value ? "text-gray-900" : ""}
          >
            {label} <ItemPopover text={desc} />
          </Tab>
        ))}
      </TabsHeader>
    </Tabs>
  );
}

export const ProtocolMetadata = ({ metadata }: { metadata: Metadata }) => {
  const [protocolNumber, setProtocolNumber] = useState<string>(
    metadata?.protocolNumber
  );

  const [studyAcronym, setStudyAcronym] = useState<string>(
    metadata?.studyAcronym
  );

  const [resortNumber, setResortNumber] = useState<string>(
    metadata?.resortNumber
  );

  const [mainResearcher, setMainResearcher] = useState<string>(
    metadata?.mainResearcher
  );

  const [visitNumber, setVisitNumber] = useState<number>(metadata?.visitNumber);
  const [visitType, setVisitType] = useState<string>(metadata?.visitType);

  // const [visitDate, setVisitDate] = useState<Date>(metadata?.visitDate);
  const [visitDate, setVisitDate] = useState<Value>([
    metadata?.visitDate,
    metadata?.visitDateEnd,
  ]);

  const [activeVisit, setActiveVisit] = useState<VisitTypes>(
    metadata.visitType ? VisitTypes.VISITTYPE : VisitTypes.VISITNUMBER
  );

  useEffect(() => {
    if (!metadata?.visitDate) {
      setVisitDate([new Date(), null]);
      updateMetadata((metadata: Metadata) => {
        metadata.visitDate = new Date();
        return metadata;
      });
    }
  }, [metadata?.visitDate]);

  const { updateMetadata } = useMetadata();

  const updateVisitNumber = (val: string) => {
    function nanToZero(x) {
      if (isNaN(x)) {
        return 0;
      }
      return x;
    }
    setVisitNumber(nanToZero(Number.parseInt(val)));
    updateMetadata((metadata: Metadata) => {
      metadata.visitNumber = nanToZero(Number.parseInt(val));
      return metadata;
    });
  };

  const updateVisitType = (val: string) => {
    setVisitType(val);
    updateMetadata((metadata: Metadata) => {
      metadata.visitType = val;
      return metadata;
    });
  };

  return (
    <>
      <div className="sm:rounded-lg border">
        <div
          className="grid grid-cols-6
          sm:rounded-lg
          text-sm
             bg-gray-50
               divide-x text-sm"
        >
          <div className="col-span-1 p-3">
            Numer protokołu{" "}
            <ItemPopover text="Wpisz numer protokołu badania." />
          </div>
          <div className="col-span-1 p-3">
            Akronim Badania
            <ItemPopover text="Wpisz ogólnie używaną nazwę badania w postaci akronimu ." />
          </div>
          <div className="col-span-1 p-3">
            Numer ośrodka
            <ItemPopover text="Wpisz wyłącznie numer ośrodka zgodnie z zastosowaną numeracją w badaniu." />
          </div>
          <div className="col-span-1 p-3">
            Główny Badacz{" "}
            <ItemPopover text="Wpisz pełny tytuł naukowy, imię i nazwisko Głównego Badacza." />
          </div>
          <div className="col-span-1 p-3">
            <UnderlineTabs
              activeVisit={activeVisit}
              setActiveVisit={setActiveVisit}
            />
          </div>
          <div className="col-span-1 p-3">Data wizyty</div>
        </div>

        <div
          className="grid grid-cols-6
              max-h-50
              divide-x border"
        >
          <div className="col-span-1">
            <input
              className="border"
              style={{
                width: "99%",
                height: "100%",
              }}
              maxLength={30}
              value={protocolNumber}
              onChange={(e) => {
                setProtocolNumber(e.target.value);
                updateMetadata((metadata: Metadata) => {
                  metadata.protocolNumber = e.target.value;
                  return metadata;
                });
              }}
            ></input>
          </div>

          <div className="col-span-1">
            <input
              className="border"
              style={{
                width: "99%",
                height: "100%",
              }}
              maxLength={30}
              value={studyAcronym}
              onChange={(e) => {
                setStudyAcronym(e.target.value);
                updateMetadata((metadata: Metadata) => {
                  metadata.studyAcronym = e.target.value;
                  return metadata;
                });
              }}
            ></input>
          </div>
          <div className="col-span-1">
            <input
              className="border"
              style={{
                width: "99%",
                height: "100%",
              }}
              value={resortNumber}
              maxLength={30}
              onChange={(e) => {
                setResortNumber(e.target.value);
                updateMetadata((metadata: Metadata) => {
                  metadata.resortNumber = e.target.value;
                  return metadata;
                });
              }}
            ></input>
          </div>
          <div className="col-span-1">
            <input
              className="border"
              style={{
                width: "99%",
                height: "100%",
              }}
              value={mainResearcher}
              maxLength={100}
              onChange={(e) => {
                setMainResearcher(e.target.value);
                updateMetadata((metadata: Metadata) => {
                  metadata.mainResearcher = e.target.value;
                  return metadata;
                });
              }}
            ></input>
          </div>

          <div className="col-span-1">
            <input
              className="border"
              style={{
                width: "99%",
                height: "100%",
              }}
              value={
                activeVisit == VisitTypes.VISITNUMBER ? visitNumber : visitType
              }
              onChange={(e) => {
                if (activeVisit == VisitTypes.VISITNUMBER) {
                  updateVisitNumber(e.target.value);
                  updateVisitType("");
                } else {
                  updateVisitNumber("null");
                  updateVisitType(e.target.value);
                }
              }}
            ></input>
          </div>

          <div className="col-span-1 text-center">
            <DateRangePicker
              format="dd/MM/yyyy"
              onChange={(date) => {
                const startDate = date[0];
                const endDate = date[1];
                setVisitDate([startDate, endDate]);
                updateMetadata((metadata: Metadata) => {
                  metadata.visitDate = formatToDateOnly(startDate as Date);
                  metadata.visitDateEnd = formatToDateOnly(endDate as Date);
                  return metadata;
                });
              }}
              value={visitDate}
            />

            {/* <DatePicker
              // format="dd/MM/y"
              returnValue="range"
              value={[new Date(), new Date()]}
              onChange={(date) => {
                console.log("date is", date);
                // let parsed = formatToDateOnly(date as Date);
                // setVisitDate(parsed);
                // updateMetadata((metadata: Metadata) => {
                //   metadata.visitDate = parsed;
                //   return metadata;
                // });
              }}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};
