import axios from "axios";
import { Metadata, ProblemsDictionary } from "../types/Metadata";
import {
  Answer,
  EditableReportQuestion,
  FormTemplate,
  ParticipantAnswer,
  ProblemAnswer,
  RaportSection,
  Report,
  ReportFile,
  ReportState,
  ReviewComment,
  TemplateEditableSection,
} from "../types/Rows";
import { ApiURI } from "../types/configs";
import { headers } from "./headers";
import { StudyInstance } from "./studyApi";

export const ReportsInstance = axios.create({
  baseURL: ApiURI + "Reports/",
});

export const TemplatesInstance = axios.create({
  baseURL: ApiURI + "Templates/",
});

export const fetchReports = async () => {
  let result = await ReportsInstance.get<Report[]>("", headers());
  return result.data;
};

export const fetchReportsByState = async (state: ReportState) => {
  let result = await ReportsInstance.get<Report[]>(
    "status/" + state,
    headers()
  );
  return result.data;
};

export const fetchReport = async (id: string) => {
  return (await ReportsInstance.get<Report>(id, headers())).data;
};

export const fetchMetadata = async (id: string) => {
  return (await ReportsInstance.get<Metadata>(id + "/Metadata/", headers()))
    .data;
};

export const fetchProblems = async (id: string) => {
  return (
    await ReportsInstance.get<ProblemAnswer[]>(id + "/problems/", headers())
  ).data;
};

export const deleteProblem = async (reportId: string, id: string) => {
  return (
    await ReportsInstance.delete<any>(reportId + "/problems/" + id, headers())
  ).data;
};

export const fetchSections = async (id: string) => {
  return (
    await ReportsInstance.get<RaportSection[]>(id + "/questions/", headers())
  ).data;
};

export const updateReportAnswers = async (
  reportId: string,
  answers: Answer[]
) => {
  return await ReportsInstance.post<any>(
    reportId + "/answers/",
    answers,
    headers()
  );
};

export const copyAnswersFromPreviousReport = async (
  reportId: string,
  otherReportId: string
) => {
  return await ReportsInstance.put<any>(
    reportId + "/reports/" + otherReportId,
    {},
    headers()
  );
};

export const updateMetadata = async (raportId: string, metadata: Metadata) => {
  return await ReportsInstance.put<any>(
    `${raportId}/metadata`,
    metadata,
    headers()
  );
};

export const fetchTemplate = async (id: string) => {
  return (await TemplatesInstance.get<FormTemplate>(id, headers())).data;
};

export const deleteTemplate = async (id: string) => {
  console.log("deleting template id", id);
  return await TemplatesInstance.delete<any>(id, headers());
};

export const updateTemplateName = async (id: string, name: string) => {
  return await TemplatesInstance.patch<any>(id + "/name/" + name, headers());
};

export const toggleTemplate = async (id: string) => {
  return await TemplatesInstance.put<any>(id + "/locked", {}, headers());
};

export const deleteSection = async (templateId: string, id: string) => {
  return await TemplatesInstance.delete<any>(
    templateId + "/sections/" + id,
    headers()
  );
};
export const deleteReport = async (id: string) => {
  return await ReportsInstance.delete<any>(id, headers());
};

export const getParticipants = async (id: string) => {
  return (
    await ReportsInstance.get<ParticipantAnswer[]>(
      id + "/participants",
      headers()
    )
  ).data;
};

export const getReportFiles = async (id: string) => {
  return (await ReportsInstance.get<ReportFile[]>(id + "/file", headers()))
    .data;
};

export const GetReportFile = async (reportId: string, id: string) => {
  return (
    await ReportsInstance.get(id + "/file/" + id, {
      responseType: "arraybuffer", // Ensure responseType is 'arraybuffer'
      ...headers(),
    })
  ).data;
};

export const UploadFile = async (reportId: string, file: any, name: string) => {
  let header = headers().headers;

  return await ReportsInstance.post(reportId + "/file/" + name, file, {
    headers: {
      Authorization: header.Authorization,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteFile = async (reportId: string, fileId: string) => {
  let header = headers().headers;

  return await ReportsInstance.delete(reportId + "/file/" + fileId, {
    headers: {
      Authorization: header.Authorization,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addParticipant = async (id: string, participantNumber: string) => {
  return await ReportsInstance.post<any>(
    id + "/participants/" + participantNumber,
    {},
    headers()
  );
};

export const removeParticipant = async (
  id: string,
  participantNumber: string
) => {
  return await ReportsInstance.delete<any>(
    id + "/participants/" + participantNumber,
    headers()
  );
};

export const updateReportState = async (id: string, state: ReportState) => {
  return await ReportsInstance.patch<any>(
    id + "/state/" + state,
    {},
    headers()
  );
};

export const PUTparticipants = async (
  id: string,
  participants: ParticipantAnswer[]
) => {
  return await ReportsInstance.put<any>(
    id + "/participants",
    participants,
    headers()
  );
};

export const fetchAllQuestions = async () => {
  return (
    await TemplatesInstance.get<EditableReportQuestion[]>(
      "questions/",
      headers()
    )
  ).data;
};

export const fetchTemplateQuestions = async (id: string) => {
  return (
    await TemplatesInstance.get<TemplateEditableSection[]>(
      id + "/questions/",
      headers()
    )
  ).data;
};

export const assignQuestionToSection = async (
  templateId: string,
  sectionId: string,
  questions: string[]
) => {
  return await TemplatesInstance.post<EditableReportQuestion[]>(
    templateId + "/sections/" + sectionId + "/questions",
    questions,
    headers()
  );
};

export const assignQuestionsFromTemplate = async (
  templateId: string,
  sourceId: string
) => {
  return await TemplatesInstance.put<any>(
    templateId + "/questions/templates/" + sourceId,
    {},
    headers()
  );
};

export const updateSections = async (
  templateId: string,
  sections: TemplateEditableSection[]
) => {
  return await TemplatesInstance.put<any>(
    templateId + "/sections/",
    sections,
    headers()
  );
};

export const getAllAvailableTemplates = async (studyId: string) => {
  return await StudyInstance.get<FormTemplate[]>(
    studyId + "/templates",
    headers()
  );
};

export const createFromExistingTemplate = async (
  studyId: string,
  originTemplateId: string
) => {
  return await TemplatesInstance.post<any>(
    "",
    {
      studyId: studyId,
      originTemplateId: originTemplateId,
    },
    headers()
  );
};

export const addSection = async (templateId: string, name: string) => {
  return await ReportsInstance.post<any>(
    "templates/" + templateId + "/sections/" + name,
    {},
    headers()
  );
};

export const getComments = async (reportId: string) => {
  return await ReportsInstance.get<ReviewComment[]>(
    reportId + "/comments/",
    headers()
  );
};

export const addComment = async (
  reportId: string,
  parentId: string,
  val: string
) => {
  return await ReportsInstance.post<any>(
    reportId + "/comments/" + parentId,
    { value: val },
    headers()
  );
};

export const updateCommentState = async (
  reportId: string,
  id: string,
  state: number
) => {
  return await ReportsInstance.patch<any>(
    reportId + "/comments/" + id + "/" + state,
    {},
    headers()
  );
};

export const addQuestion = async (
  templateId: string,
  sectionId: string,
  question: any
) => {
  return await ReportsInstance.post<any>(
    "templates/" + templateId + "/sections/" + sectionId + "/questions",
    question,
    headers()
  );
};

export const updateTemplateProblemAnswers = async (
  reportId: string,
  answers: ProblemAnswer[]
) => {
  return await ReportsInstance.post<any>(
    reportId + "/answers/problems/",
    answers,
    headers()
  );
};

export const getTemplate = async (id: string) => {
  return (await TemplatesInstance.get<FormTemplate>(id, headers())).data;
};

export const getTemplates = async () => {
  return (await TemplatesInstance.get<FormTemplate[]>("", headers())).data;
};

export const addTemplate = async (templateName: string) => {
  return await TemplatesInstance.post<any>(
    "",
    {
      value: templateName,
    },
    headers()
  );
};

// TODO - use in scope of reports
export const updateTemplateProblemQuestions = async (
  reportId: string,
  answers: ProblemAnswer[]
) => {
  return await ReportsInstance.put<any>(
    reportId + "/questions/problems",
    answers,
    headers()
  );
};

export const addProblemFromExisting = async (
  reportId: string,
  value: string
) => {
  return await ReportsInstance.post<any>(
    reportId + "/questions/problems",
    { value: value },
    headers()
  );
};

export const addNewProblem = async (reportId: string, value: string) => {
  return await ReportsInstance.post<any>(
    reportId + "/questions/ProblemType/Category/",
    { value: value },
    headers()
  );
};

export const updateQuestions = async (
  templateId: string,
  questions: EditableReportQuestion[]
) => {
  return await TemplatesInstance.put<any>(
    templateId + "/questions",
    questions,
    headers()
  );
};

export const deleteQuestion = async (
  templateId: string,
  questionsId: string
) => {
  return await TemplatesInstance.delete<any>(
    templateId + "/questions/" + questionsId,
    headers()
  );
};

export const updateFootnote = async (templateId: string, value: string) => {
  return await TemplatesInstance.patch<any>(
    templateId + "/footnote/",
    { value: value },
    headers()
  );
};

export const updateFootnoteFul = async (templateId: string, value: string) => {
  return await TemplatesInstance.patch<any>(
    templateId + "/footnoteful/",
    { value: value },
    headers()
  );
};
