import axios, { AxiosInstance } from "axios";
import { Breadcrumb, Dropdown } from "flowbite-react";
import { useEffect } from "react";
import { HiHome } from "react-icons/hi";

import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { ReportsInstance, TemplatesInstance } from "../../api/reportsApi";
import { StudyInstance } from "../../api/studyApi";
import { UsersInstance } from "../../api/userApi";
import { useMsal } from "@azure/msal-react";
import { useUserContext } from "./authProvider";
import { Button } from "@material-tailwind/react";

const Navbar = ({ items }: { items: NavigationBadge[] }) => {
  const { userName } = useUserContext();
  const { instance } = useMsal();
  
  const clients = [
    UsersInstance,
    TemplatesInstance,
    ReportsInstance,
    StudyInstance,
  ];

  useEffect(() => {
    // clients.forEach((x) => {
    //   handleUnauthorized(x);
    // });
    let token = localStorage.getItem("webApiAccessToken");
    if (token) {
      clients.forEach((x) => {
        x.defaults.headers.common["Authorization"] = "Bearer " + token;
      });
    }
  }, [clients, instance]);

  return (
    <div
      style={{ zIndex: 100 }}
      className="bg-gray-50 px-5 py-3 dark:bg-gray-900 flex justify-between z-10"
    >
      <Breadcrumb aria-label="Solid background breadcrumb example">
        <Breadcrumb.Item href="/" icon={HiHome} />
        {items.map((item, index) => {
          return (
            <>
              <Breadcrumb.Item className="text-black" href={item.url}>
                {!item.links && <>{item.text} </>}
              </Breadcrumb.Item>

              {item.links && (
                <Dropdown style={{ zIndex: 100 }} label={item.text} inline>
                  {item.links?.map((dropdownLink) => (
                    <Dropdown.Item href={dropdownLink.value}>
                      {dropdownLink.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              )}
            </>
          );
        })}
      </Breadcrumb>
      <div className="flex gap-5">
        <a href="/">
          {" "}
          <Button variant="text">Badania</Button>
        </a>
        <a href="/reports">
          {" "}
          <Button variant="text">Do weryfikacji</Button>
        </a>
        <a href="/Issues">
          {" "}
          <Button variant="text">Lista znalezisk</Button>
        </a>{" "}
        <a href="/Questions">
          {" "}
          <Button variant="text">Pytania</Button>
        </a>
        {/* <p>
          {" "}
          <AiOutlineUser />
        </p> */}
        <Button variant="text">{userName}</Button>
        <Button
          onClick={() => {
            instance.logout();
          }}
          variant="text"
        >
          Wyloguj się
        </Button>
      </div>
    </div>
  );
};

export const ProtectedRoute = ({ children }: any) => {
  const navigate = useNavigate();

  const navigationBadges = useNavigationBadgesStore(
    (state: any) => state.navigationBadges
  );

  // If authenticated, render the child routes
  return (
    <>
      <Navbar items={navigationBadges} />
      <Outlet />
    </>
  );
};
