import axios from "axios";
import { setegid } from "process";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export interface UserContext {
  userName: string;
  email: string;
  parsedName: string;
}
const AuthContext = createContext<UserContext>({
  userName: "",
  email: "",
  parsedName: "",
});

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(
    localStorage.getItem("webApiAccessToken")
  );
  const [userName, setUserName] = useState("");
  const [parsedName, setParsedName] = useState("");
  const [email, setEmail] = useState("");

  const [isAdmin, setIsAdmin] = useState(false);
  // Function to set the authentication token

  useEffect(() => {
    if (token) {
      const vals = JSON.parse(atob(token.split(".")[1]));
      
      if (vals?.name && vals.name.toString().toLowerCase() !== "unknown") {
        setUserName(vals?.name);
        setParsedName(vals.name);
      } else if (vals?.emails[0]) {
        setUserName(vals?.emails[0]);
        setParsedName(parseEmail(vals?.emails[0]));
      }

      if (vals?.emails[0]) {
        setEmail(vals?.emails[0]);
      }
    }
  }, [token]);

  function parseEmail(email) {
    try {
      // Split the email address by '@'
      const parts = email.split("@");

      // Get the part before '@'
      const name = parts[0];

      // Split the name by '.'
      const nameParts = name.split(".");

      // Extract the name and surname
      const firstName = nameParts[0];
      const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";

      return firstName + " " + lastName;
    } catch {
      return "";
    }
  }

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      userName,
      email,
      parsedName,
    }),
    [userName, parsedName, email]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useUserContext = () => useContext(AuthContext);

export default AuthProvider;
