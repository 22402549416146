import axios from "axios";
import { ApiURI } from "../types/configs";
import { headers } from "./headers";
import { Log } from "../models/Log";

export const UsersInstance = axios.create({
  baseURL: ApiURI + "Users/",
});

export const loginUser = async (username: string, password: string) => {
  return await UsersInstance.post<{ token: string }>(
    "login",
    {
      username: username,
      password: password,
    },
    headers()
  );
};

export const registerUser = async (username: string, password: string) => {
  return await UsersInstance.post<{ token: string }>(
    "register",
    {
      username: username,
      password: password,
    },
    headers()
  );
};

export const fetchEmployeesWithAccess = async (studyId: string) => {
  return (await UsersInstance.get<any>("access/" + studyId, headers())).data;
};

export const assignAccessToEmployee = async (
  email: string,
  studyId: string
) => {
  return (
    await UsersInstance.post<any>(email + "/access/" + studyId, headers())
  ).data;
};

export const fetchLogs = async (
  top: number,
  page: number = 0,
  pageSize: number = 10
) => {
  return (
    await UsersInstance.get<Log[]>(
      `/logs?top=${top}&page=${page}&pageSize=${pageSize}`,
      headers()
    )
  ).data;
};
