import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PUTparticipants,
  updateMetadata,
  updateReportAnswers,
  updateTemplateProblemAnswers,
} from "../../api/reportsApi";
import { Answer, ParticipantAnswer, ProblemAnswer } from "../../types/Rows";
import { Metadata } from "../../types/Metadata";
import {
  useMetadata,
  useParticipantAnswers,
  useProblemAnswers,
  useSectionQuestions,
} from "../..";
import { failureSaveToast, successToast } from "../../helpers/successToast";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "../../components/atomic/spinner";
import { Button } from "@material-tailwind/react";

export const SaveItems = ({
  reportId,
  setIsExportLocked,
  problemAnswers,
  questionAnswers,
  participantAnswers,
  metadata,
}: {
  reportId: string;
  setIsExportLocked: (boolean) => void;
  problemAnswers;
  questionAnswers;
  participantAnswers;
  metadata;
}) => {
  const queryClient = useQueryClient();

  const { clear: clearProblems } = useProblemAnswers();
  const { clear: clearQuestions } = useSectionQuestions();
  const { clear: clearParticipants } = useParticipantAnswers();
  const { clear: clearMetadata } = useMetadata();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: saveAnswers, isSuccess: isSaveAnswersSuccess } =
    useMutation(
      async (answers: Answer[]) => {
        if (answers.length > 0) return updateReportAnswers(reportId!, answers);
      },
      {
        onSuccess: () => {
          queryClient.resetQueries([reportId, "Sections"]);
        },
      }
    );

  const { mutateAsync: saveProblemsAnswers, isSuccess: isSaveProblemSuccess } =
    useMutation(
      async (problemAnswers: ProblemAnswer[]) => {
        if (problemAnswers.length > 0)
          return updateTemplateProblemAnswers(reportId!, problemAnswers);
      },
      {
        onSuccess: () => {
          queryClient.resetQueries([reportId, "Problems"]);
        },
      }
    );

  const { mutateAsync: saveMetaData, isSuccess: isMetadataSaveSuccess } =
    useMutation(
      async (metadata: Metadata) => updateMetadata(reportId!, metadata),
      {
        onSuccess: () => {
          queryClient.resetQueries([reportId, "Metadata"]);
        },
      }
    );

  const { mutateAsync: saveParticipants } = useMutation(
    async (participants: ParticipantAnswer[]) => {
      if (participants.length > 0)
        return PUTparticipants(reportId!, participants);
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([reportId, "Participants"]);
      },
    }
  );

  // const invalidateReport = useCallback(
  //   () => queryClient.resetQueries([reportId]),
  //   [reportId, queryClient]
  // );

  return (
    <Button
      onClick={async () => {
        setIsLoading(true);
        try {
          if (metadata)
            await saveMetaData(metadata!).then(() => clearMetadata());
        } catch {
          queryClient.resetQueries([reportId, "Metadata"]);
          failureSaveToast("Informacje o badaniu");
        }
        const problems = [...problemAnswers?.values()];
        const questions = [...questionAnswers?.values()];
        const participants = [...participantAnswers.values()];

        Promise.all([
          saveProblemsAnswers(problems)
            .catch(() => {
              failureSaveToast("Issue");
              queryClient.resetQueries([reportId, "Problems"]);
            })
            .then(() => clearProblems()),
          saveAnswers(questions)
            .catch(() => {
              failureSaveToast("Pytania");
              queryClient.resetQueries([reportId, "Sections"]);
            })
            .then(() => clearQuestions()),
          saveParticipants(participants)
            .catch(() => {
              failureSaveToast("Uczestnicy");
              queryClient.resetQueries([reportId, "Participants"]);
            })
            .then(() => clearParticipants()),
        ])
          .then(() => {
            setIsExportLocked(false);
            successToast();
            window.location.reload();
            // invalidateReport();
          })
          .finally(() => setIsLoading(false));
      }}
    >
      {isLoading ? <Spinner /> : "Zapisz"}
    </Button>
  );
};
