import axios from "axios";
import { ApiURI } from "../types/configs";
import { FormTemplate, Study } from "../types/Rows";
import { headers } from "./headers";

export const StudyInstance = axios.create({
  baseURL: ApiURI + "Study/",
});

export const fetchStudies = async () => {
  return (await StudyInstance.get<Study[]>("", headers())).data;
};

export const deleteStudy = async (id: string) => {
  return await StudyInstance.delete(id, headers());
};

export const fetchStudy = async (studyId: string) => {
  return (await StudyInstance.get<Study>(studyId, headers())).data;
};

export const addResort = async (
  studyId: string,
  resortName: string,
  resortAddress: string
) => {
  console.log({
    value: resortName,
    address: resortAddress,
  });
  return await StudyInstance.post<Study>(
    studyId + "/resorts/",
    {
      value: resortName,
      address: resortAddress,
    },
    headers()
  );
};

export const editResort = async (
  studyId: string,
  resortId: string,
  resortName: string,
  resortAddress: string
) => {
  return await StudyInstance.put<any>(
    studyId + "/resorts/" + resortId,
    {
      value: resortName,
      address: resortAddress,
    },
    headers()
  );
};

export const deleteResort = async (studyId: string, resortId: string) => {
  return await StudyInstance.delete<any>(
    studyId + "/resorts/" + resortId,
    headers()
  );
};

export const updateStudyDescription = async (
  studyId: string,
  description: string
) => {
  return await StudyInstance.post<Study>(
    studyId + "/description/",
    {
      value: description,
    },
    headers()
  );
};

export const addReport = async (
  studyId: string,
  resortId: string,
  templateId: string
) => {
  return await StudyInstance.post<any>(
    studyId + "/resorts/" + resortId + "/reports/",
    {
      value: templateId,
    },
    headers()
  );
};

export const addStudy = async (studyName: string) => {
  return await StudyInstance.post<any>(
    "",
    {
      value: studyName,
    },
    headers()
  );
};

export const getAccess = async (id: string) => {
  return (await StudyInstance.get<any>(id + "/access", headers())).data;
};
