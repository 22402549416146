import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QuestionPage } from "./pages/questions/questionPage";
import { QuestionsPage } from "./pages/questions/questionsPage";
import { ReportPageWrapper } from "./pages/report/Report";
import { Root } from "./pages/root";
import { StudyPage } from "./pages/studies/studyPage";
import { TemplatePageWrapper } from "./pages/template/templatePage";
import { Login } from "./pages/user/Login";
import { ProtectedRoute } from "./pages/user/ProtectedRoute";
import { loginRequest } from "./pages/user/ab2cAuthProvider";
import { IssuesPage } from "./pages/issues/issuesPage";
import { IssuePage } from "./pages/issues/issuePage";
import { Reports } from "./pages/root/Reports";
import LoginPage from "./pages/login/LoginPage";
import { AuditPage } from "./pages/audit/AuditPage";

export const Router = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    let t = async () => {
      try {
        const token = await instance.acquireTokenSilent(loginRequest);
        localStorage.setItem("webApiAccessToken", token.accessToken);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token.accessToken}`;
        setIsToken(true);
      } catch (err) {
        if (err.errorMessage.includes("AADB2C90077")) {
          instance.loginRedirect();
        }
      }
    };
    if (
      accounts[0] &&
      inProgress != "startup" &&
      instance?.getActiveAccount() &&
      !isToken
    ) {
      t();
    }
  }, [instance, inProgress]);

  const routesForAuthenticatedOnly = [
    {
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element: <Root />,
        },
        {
          path: "/reports/",
          element: <Reports />,
        },
        {
          path: "/reports/:reportId",
          element: <ReportPageWrapper />,
        },
        {
          path: "/study/:studyId",
          element: <StudyPage />,
        },
        {
          path: "/templates/:templateId",
          element: <TemplatePageWrapper />,
        },
        {
          path: "/questions/",
          element: <QuestionsPage />,
        },
        {
          path: "/questions/:questionId",
          element: <QuestionPage />,
        },
        { path: "/issues/", element: <IssuesPage /> },
        {
          path: "/issues/:categoryId",
          element: <IssuePage />,
        },
        {
          path: "/audit",
          element: <AuditPage />,
        },
      ],
    },
  ];

  const router = createBrowserRouter([...routesForAuthenticatedOnly]);
  const acc = instance.getActiveAccount();
  return (
    <>
      {acc ? (
        <AuthenticatedTemplate>
          <RouterProvider router={router} />{" "}
        </AuthenticatedTemplate>
      ) : (
        <Login />
      )}
    </>
  );
};
