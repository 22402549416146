import React from 'react';
import AuditComponent from './AuditComponent';

const AuditPage: React.FC = () => {
  return (
    <div>
      <AuditComponent />
    </div>
  );
};

export { AuditPage };
